.dashboard-container {
    height: 0px;
    position: relative;
    padding-bottom: 60%;
}

.dashboard {
    height: 100%;
    position: absolute;
}
